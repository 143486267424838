const initialState = {
  isChapterTwoUnlocked: false,
  isChapterThreeUnlocked: false,
  isChapterFourUnlocked: false,
  isChapterFiveUnlocked: false,
};

const portfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UNLOCK_CHAPTER_TWO":
      return {
        ...state,
        isChapterTwoUnlocked: true,
      };

    case "UNLOCK_CHAPTER_THREE":
      return {
        ...state,
        isChapterThreeUnlocked: true,
      };

    case "UNLOCK_CHAPTER_FOUR":
      return {
        ...state,
        isChapterFourUnlocked: true,
      };

    case "UNLOCK_CHAPTER_FIVE":
      return {
        ...state,
        isChapterFiveUnlocked: true,
      };

    default:
      return state;
  }
};

export default portfolioReducer;
