import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import LoadingText from "./Component/Loading/LoadingText";

import "./App.css";

const Lyric = React.lazy(() => import("./Component/Lyrics/Lyric"));
const Home = React.lazy(() => import("./Component/Home/Home"));
const Loading = React.lazy(() => import("./Component/Loading/Loading"));
const Message = React.lazy(() => import("./Component/Message/Message"));
const WordDrop = React.lazy(() => import("./Component/WordDrop/WordDropPanel"));
const LevelComplete = React.lazy(() =>
  import("./Component/LevelComplete/LevelComplete")
);
const ChapterComplete = React.lazy(() =>
  import("./Component/ChapterComplete/ChapterComplete")
);

const App = () => {
  const scene = useSelector((state) => state.GameHelperReducer.scene);
  const loading = <LoadingText />;

  useEffect(() => {
    window.onresize();
  }, []);

  window.onresize = function () {
    document.body.height = window.innerHeight;
  };

  let component = "";
  switch (scene) {
    case "Loading":
      component = (
        <>
          <Suspense fallback={loading}>
            <Loading />
          </Suspense>
        </>
      );
      break;

    case "LevelComplete":
      component = (
        <>
          <Suspense fallback={loading}>
            <LevelComplete />
          </Suspense>
        </>
      );
      break;

    case "ChapterComplete":
      component = (
        <>
          <Suspense fallback={loading}>
            <ChapterComplete />
          </Suspense>
        </>
      );
      break;

    case "WordDrop":
      component = (
        <>
          <Suspense fallback={loading}>
            <WordDrop />
          </Suspense>
        </>
      );

      break;

    case "Message":
      component = (
        <>
          <Suspense fallback={loading}>
            <Message />
          </Suspense>
        </>
      );

      break;

    case "Song":
      component = (
        <>
          <Suspense fallback={loading}>
            <Lyric />
          </Suspense>
        </>
      );
      break;

    case "Home":
      component = (
        <>
          <Suspense fallback={loading}>
            <Home />
          </Suspense>
        </>
      );
      break;

    default:
      component = (
        <>
          <Suspense fallback={loading}>
            <Loading />
          </Suspense>
        </>
      );
      break;
  }

  return <>{component}</>;
};

export default App;
