const initialState = {
  level: 0,
  chapter: 0,
  time: 0,
  scene: "",
};

const GameHelperReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHAPTER_ONE_LYRIC":
      return {
        ...state,
        chapter: 1,
        scene: "Song",
        level: state.level + 1,
        time: 0,
      };

    case "CHAPTER_TWO_LYRIC":
      return {
        ...state,
        chapter: 2,
        scene: "Song",
        level: state.level + 1,
        time: 0,
      };

    case "CHAPTER_THREE_LYRIC":
      return {
        ...state,
        chapter: 3,
        scene: "Song",
        level: state.level + 1,
        time: 0,
      };

    case "CHAPTER_FOUR_LYRIC":
      return {
        ...state,
        chapter: 4,
        scene: "Song",
        level: state.level + 1,
        time: 0,
      };

    case "CHAPTER_FIVE_LYRIC":
      return {
        ...state,
        chapter: 5,
        scene: "Song",
        level: state.level + 1,
        time: 0,
      };

    case "CHANGE_SCENE":
      return {
        ...state,
        scene: action.payload,
      };

    case "RECORD_TIME":
      return {
        ...state,
        time: action.payload,
      };

    case "RESET_GAME":
      return {
        ...state,
        level: 0,
        chapter: 0,
        time: 0,
      };

    default:
      return state;
  }
};

export default GameHelperReducer;
