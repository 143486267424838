import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import GameHelperReducer from "./GameHelperReducer";
import portfolioReducer from "./PortfolioReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["GameHelperReducer"],
};

const allReducers = combineReducers({
  GameHelperReducer,
  portfolioReducer,
});

export default persistReducer(persistConfig, allReducers);
